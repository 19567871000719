import { styled } from 'twin.macro';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';

import Seo from './Seo';
import Layout from './Layout';
import Heading from './atoms/Heading';
import SanityImage from './SanityImage';

const Container = styled.div`
  h1 {
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
  h3 {
    font-size: 1.5rem;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
  }
  ul li {
    list-style: disc;
    margin-left: 1rem;
  }
  ol li {
    list-style: decimal;
    margin-left: 1rem;
  }
  ol {
    margin-bottom: 1rem;
  }
  p {
    margin-bottom: 1rem;
    font-size: 16px;
  }
  .page-content {
    img {
      display: flex;
      margin: 3rem auto 4rem auto;
      box-shadow: ${(props) => props.theme.boxShadow};
    }
  }
`;

export const query = graphql`
  query PageViewQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      image {
        asset {
          gatsbyImageData(width: 1600)
        }
      }
      details
    }
  }
`;

const AboutPage = ({ data }) => {
  const pageData = data.sanityPage;
  return (
    <Layout>
      <Seo title={pageData.title} />
      <Container>
        {pageData.image && (
          <div tw="mx-auto max-w-screen-2xl text-center">
            <SanityImage fluid={pageData.image.asset.gatsbyImageData} />
          </div>
        )}
        <div tw="container mx-auto px-4">
          <div tw="pt-8">
            <Heading type="h1">{pageData.title}</Heading>
          </div>
          <div tw="my-14">
            <ReactMarkdown>{pageData.details}</ReactMarkdown>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default AboutPage;
